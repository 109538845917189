import { AMaterialLessonBlockTemplate } from "../../models";

const images = ["/images/figma_image_rect.png"];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const ImagesAndTexts: AMaterialLessonBlockTemplate[] = [
  {
    name: "content 1",
    template: {
      name: "content 1",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "250px",
            height: "200px",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "fit-content",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },
  // content 2:

  {
    name: "content 2",
    template: {
      name: "content 2",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "250px",
            height: "200px",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "fit-content",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        flexDirection: "row-reverse",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  // content 3
  {
    name: "content 3",
    template: {
      name: "content 3",
      groups: [
        // First image:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "250px",
            height: "200px",
          },
        },
        // Second image:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "250px",
            height: "200px",
          },
        },
        // Description part side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },
  // Content 4:

  {
    name: "content 4",
    template: {
      name: "content 4",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "40%",
            height: "40%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  // content 5

  {
    name: "content 5",
    template: {
      name: "content 5",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "40%",
            height: "50%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "row-reverse",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  {
    name: "content 6",
    template: {
      name: "content 6",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "50%",
            height: "60%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "50%",
            height: "60%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },
  {
    name: "content 7",
    template: {
      name: "content 7",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "40%",
            height: "60%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "50%",
            height: "60%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "row-reverse",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  {
    name: "content 8",
    template: {
      name: "content 8",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "65%",
            height: "50%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "55%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  {
    name: "content 9",
    template: {
      name: "content 9",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "65%",
            height: "50%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "55%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "row-reverse",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  {
    name: "content 10",
    template: {
      name: "content 10",
      groups: [
        // left side Which will be the image:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },

  {
    name: "content 11",
    template: {
      name: "content 11",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "55%",
            height: "100%",
          },
        },
        // Right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "70%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "row-reverse",
        width: "100%",
        height: "100%",
        padding: "33px",
      },
    },
  },
];
